import React from "react";
import PropTypes from "prop-types";
import "./IndianFlavorsMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const IndianFlavorsMenu = ({ menuItems }) => {
  const generateIndianFlavorsMenu = () => {
    return (
      <div className="indian-flavors-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.indianItems.map((indianItem, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="indian-flavors-menu-option">
                <div className="menu-option-label">
                  <div style={{ marginBottom: "5px", fontSize: "large" }}>
                    {indianItem.indianItem}
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    {indianItem.description}
                  </div>
                  <div style={{ fontSize: "small" }}>${indianItem.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div>
      <div>
        <CollapsibleContainerOrder
          formTitle={"Other Treats"}
          formFunction={generateIndianFlavorsMenu}
        />
      </div>
    </div>
  );
};

IndianFlavorsMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default IndianFlavorsMenu;
